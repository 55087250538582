import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../../Context/AppContext";
import Select from "../../../../Components/FormControl/Select/Select";
import AddReuestData from "./addRequestData";
import { useFormik } from "formik";
import {
  getRowMaterialsList,
  addNewRequestResource,
  updateRequestInventory,
  getRequestedInventoryById,
} from "api/inventoryProduct";
import Header from "Components/Header/Header";
import { storeList } from "api/stores";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { getAccessByModuleName } from "Routes/Routes";
import { MODULES } from "Helpers/Constants";
import styles from "../index.module.scss";
import { DateTime } from "luxon";

const AddRequest = (props) => {
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [showErrors, setShowErrors] = useState(false);

  const [rawMaterial, setMaterials] = useState([]);
  const [data, setData] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const [resourceIdUpdate, setResourceIdUpdate] = useState(); //only for update case
  const [updateData, setUpdateData] = useState({ isData: false, data: "" }); //only for update case
  const history = useHistory();
  const [viewRequest, setViewRequest] = useState(false);
  const [isLoading, setLoading] = useState(false);

  //Check accessiblity
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.REQUEST_RESOURCES);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }

  //It used globly to set initial value when needed
  const initialValue = [
    {
      id: "",
      source_inventory_product_id: 0,
      inventory_available_quantity: 0,
      raw_matrial_type: "",
      raw_matrial_unit: "",
      raw_material_price: 0,
      requested_quantity: 0,
      inventory_raw_material_id: "",
      inventory_raw_material: "",
    },
  ];

  //Intialize formik
  const formik = useFormik({
    initialValues: {
      source_store_id: 0,
      request_status: "",
      inventory_request_resource_ingredients: initialValue,
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validate: (values) => {
      if (!values.source_store_id) {
        setShowErrors(true);
      }
      values.inventory_request_resource_ingredients.forEach((value, idx) => {
        if (
          (value.inventory_raw_material_id && !value.requested_quantity) ||
          !value.requested_quantity ||
          !value.inventory_raw_material_id
        ) {
          setShowErrors(true);
        } else if (
          value.requested_quantity &&
          !value.inventory_raw_material_id
        ) {
          setShowErrors(true);
        } else {
          setShowErrors(false);
        }
      });
    },
  });

  //update values by key names
  const setValues = async (index, value) => {
    await formik.setValues({ ...formik.values, [index]: value });
  };

  //Use only in view case
  useEffect(() => {
    (async () => {
      const { viewId } = props?.match?.params;
      if (viewId && viewId > 0) {
        setViewRequest(true);
        await requestedResourceDetailHanlder(viewId);
      } else {
        setViewRequest(false);
      }
    })();
  }, []);

  //Get all list store which are availabe int the system
  useEffect(async () => {
    const selectedStore = localStorage.getItem("selectedStore");
    setShowErrors(false);
    const getStoreList = await storeList();
    if (getStoreList && getStoreList.status === 200) {
      setStoresList(
        getStoreList.data.data.rows.filter((s) => s.id != selectedStore)
      );
    } else {
      showToastMessage(undefined, getStoreList.data.message, "error", false);
    }
  }, []);

  //use for update case for get update id
  useEffect(() => {
    (async () => {
      const { updateId } = props?.match?.params;
      if (updateId !== undefined && updateId !== "" && updateId > 0) {
        setResourceIdUpdate(updateId);
      }
    })();
  }, []);

  //use in update case for get requested resource detail
  useEffect(() => {
    (async () => {
      if (resourceIdUpdate) {
        await requestedResourceDetailHanlder(resourceIdUpdate);
      }
    })();
  }, [resourceIdUpdate]);

  //get data to update
  const requestedResourceDetailHanlder = async (id) => {
    setIsLoading(true);
    const response = await getRequestedInventoryById(id);
    if (response && response.status === 200) {
      setUpdateData({ isData: true, data: response.data.data });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      props.history.push("/inventory-management/request-resources");
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  //Manage the final submit to edit detail or add new record
  const handleSubmit = async (value) => {
    //check duplicates selection of raw materials
    const inventory_raw_material =
      value.inventory_request_resource_ingredients?.map(
        (v) => v.inventory_raw_material_id
      );
    let duplicatesMaterials = [];
    let isDuplicate = false;

    //find duplicates raw materials if any
    inventory_raw_material?.some((v, idx) => {
      if (inventory_raw_material?.indexOf(v) != idx) {
        isDuplicate = true;
        const duplicateData =
          value.inventory_request_resource_ingredients?.find(
            (d) => d.inventory_raw_material_id === v
          );
        duplicatesMaterials.push(duplicateData.inventory_raw_material);
      }
    });

    if (isDuplicate) {
      showToastMessage(
        undefined,
        `Raw materials ${duplicatesMaterials.join(
          ", "
        )} are selected multiple times, please remove one of them.`,
        "error",
        false
      );
      return;
    } else if (showErrors) {
      showToastMessage(
        undefined,
        "Please add all ingredients data.",
        "error",
        false
      );
      return;
    }
    let validationFlag = false;
    value?.inventory_request_resource_ingredients.forEach((val, idx) => {
      if (!val.inventory_raw_material_id) {
        validationFlag = true;
      }
      if (!val.requested_quantity) {
        validationFlag = true;
      }
    });
    if (validationFlag) {
      showToastMessage(
        undefined,
        "Please add all ingredients data",
        "error",
        false
      );
      return;
    }
    
    try {
      setIsLoading(true);
      if (!updateData.isData) {
        const response = await addNewRequestResource(value);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          setIsLoading(false);
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
          setIsLoading(false);
        }
      } else {
        const response = await updateRequestInventory(
          updateData.data.id,
          value
        );
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          setIsLoading(false);
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      showToastMessage(undefined, error.message, "error", false);
    }
    history.push(`/inventory-management/request-resources/`);
  };

  //Handle raw materials selection and values as per the selected materials
  const onChangeHandler = (type, value, position = "") => {
    if (type === "requestInventory") {
      const selectedData = data.find(
        (da) => da.product_recipe_raw_material.id === value
      );
      const newdata = {
        inventory_raw_material_id: value,
        inventory_available_quantity: selectedData?.quantity,
        raw_material_price:
          selectedData?.product_recipe_raw_material?.whole_sale_price,
        raw_matrial_unit:
          selectedData?.product_recipe_raw_material?.product_recipe_unit
            ?.title ?? "",
        raw_matrial_type:
          selectedData?.product_recipe_raw_material?.product_recipe_type
            ?.title ?? "",
        source_inventory_product_id: selectedData?.id,
        requested_quantity: 0,
        inventory_raw_material:
          selectedData?.product_recipe_raw_material?.title,
      };
      formik.values.inventory_request_resource_ingredients[position] = newdata;
      const newRequestedValue = [
        ...formik.values.inventory_request_resource_ingredients,
      ];
      setValues("inventory_request_resource_ingredients", newRequestedValue);
    }
  };

  //It handles request quantity
  const onChangeQtyHandler = (key, value, position) => {
    const prevValue = formik.values.inventory_request_resource_ingredients;
    let newValue = prevValue[position];
    newValue.requested_quantity = value;
    formik.values.inventory_request_resource_ingredients[position] = newValue;
    setValues("inventory_request_resource_ingredients", [
      ...formik.values.inventory_request_resource_ingredients,
    ]);
  };

  //This function will handdle add new row
  const addNewRow = () => {
    if (viewRequest) {
      return;
    }
    let data = formik.values.inventory_request_resource_ingredients;
    data.push(initialValue[0]);
    formik.setValues((value) => {
      return {
        ...value,
        inventory_request_resource_ingredients: [...data],
      };
    });
  };

  //delete request resource row run time from row data
  const deleteRow = (values) => {
    if (viewRequest) {
      return;
    }
    formik.setValues((value) => {
      return {
        ...value,
        inventory_request_resource_ingredients:
          value.inventory_request_resource_ingredients.filter(
            (s) => s !== values
          ),
      };
    });
  };

  //Handle store selection and as per selected store list all active inventory data
  const getRowMaterialsData = async (storeId) => {
    setLoading(true);
    const response = await getRowMaterialsList(storeId);
    let temp = [];
    if (response && response.status === 200) {
      setData(response.data.data);
      response.data.data.map((raw) => {
        temp.push({
          id: raw.product_recipe_raw_material.id,
          title: raw.product_recipe_raw_material.title,
        });
        setMaterials(temp);
      });
      temp = [];
      await formik.setValues({
        source_store_id: storeId,
        request_status: "",
        inventory_request_resource_ingredients: initialValue,
      });
      setLoading(false);
    } else {
      setLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  //only use in update case and set requested value to update
  useEffect(async () => {
    if (updateData.isData) {
      let editData = updateData.data;
      await getRowMaterialsData(editData?.source_store_id);
      let updatedData = editData?.inventory_request_resource_ingredients?.map(
        (da) => {
          return {
            id: da.id,
            source_inventory_product_id: da?.source_inventory_product_id,
            inventory_available_quantity: da?.inventory_available_quantity,
            raw_matrial_type: da?.raw_matrial_type,
            raw_matrial_unit: da.raw_matrial_unit,
            raw_material_price: da?.raw_material_price,
            requested_quantity: da?.requested_quantity,
            inventory_raw_material_id:
              da?.sourceInventoryProduct?.inventory_raw_material_id,
            inventory_raw_material:
              da?.sourceInventoryProduct?.product_recipe_raw_material.title,
          };
        }
      );
      await formik.setValues((value) => {
        return {
          ...value,
          source_store_id: editData?.source_store_id,
          request_status: editData?.request_status,
          inventory_request_resource_ingredients: [...updatedData],
        };
      });
    } else {
      setMaterials([]);
      await formik.setValues({
        request_status: "",
        source_store_id: "",
        inventory_request_resource_ingredients: initialValue,
      });
    }
  }, [updateData.data]);

  return (
    <React.Fragment>
      <Header
        title={
          updateData.isData && viewRequest
            ? "View Request"
            : updateData.isData
            ? "Update Request"
            : "Add New Request"
        }
      />
      {viewRequest && (
        <div className="d-flex justify-content-between mb-5">
          <div className="d-flex flex-column">
            <span className={styles.heading}>Request No.</span>
            <span className={styles.content}>
              {updateData.data?.request_number}
            </span>
          </div>
          <div className="d-flex flex-column">
            <span className={styles.heading}>Source Store Name</span>
            <span className={styles.content}>
              {updateData.data?.sourceStore?.title}
            </span>
          </div>
          <div className="d-flex flex-column">
            <span className={styles.heading}>Destination Store Name</span>
            <span className={styles.content}>
              {updateData.data?.destinationStore?.title}
            </span>
          </div>
          <div className="d-flex flex-column">
            <span className={styles.heading}>Request Date</span>
            <span className={styles.content}>
              {DateTime.fromISO(updateData.data?.updatedAt)
                .setZone("America/Los_Angeles")
                .toFormat("ccc LLL dd, yyyy, hh:mm a")}
            </span>
          </div>
        </div>
      )}
      <div id="MainContent" className="main-content">
        <form
          onSubmit={(e) => {
            formik.handleSubmit(e);
          }}
        >
          <div className="row d-flex justify-content-center">
            {!viewRequest && (
              <div className="col-md-5 col-xs-12 text-left">
                <Select
                  placeholder={"Select Source Store"}
                  options={storesList}
                  onChange={(store) => {
                    getRowMaterialsData(store);
                    setValues("source_store_id", store);
                  }}
                  value={formik.values.source_store_id}
                  required={true}
                  disabled={updateData.isData}
                />
              </div>
            )}
          </div>
          <div className="row">
            <table className="rt-table table border-bottom">
              <thead className="rt-thead -header">
                <tr className="rt-tr">
                  <th className="rt-th rt-resizable-header">Raw Material</th>
                  <th className="rt-th rt-resizable-header">Unit</th>
                  <th className="rt-th rt-resizable-header">Available Qty</th>
                  <th className="rt-th rt-resizable-header">Price</th>
                  <th className="rt-th rt-resizable-header">Request Qty</th>
                  {!viewRequest && (
                    <th className="rt-th rt-resizable-header">Action</th>
                  )}
                </tr>
              </thead>
              <tbody className="rt-tbody">
                <AddReuestData
                  requestInventory={
                    formik.values.inventory_request_resource_ingredients
                  }
                  add={addNewRow}
                  delete={deleteRow.bind()}
                  rawMaterials={rawMaterial}
                  onChange={onChangeHandler}
                  setValues={onChangeQtyHandler}
                  viewRequest={viewRequest}
                  isLoading={isLoading}
                />
              </tbody>
            </table>
          </div>
          <div class={`text-center p-5`}>
            {!(
              updateData.isData &&
              updateData.data.request_status === "pending_review"
            ) &&
              !viewRequest && (
                <button
                  type="submit"
                  onClick={(e) => {
                    setValues("request_status", "saved_as_draft");
                  }}
                  className="btn gray-btn d-inline-block mb-3 mr-3"
                >
                  Save as Draft
                </button>
              )}
            {!viewRequest && (
              <button
                type="submit"
                onClick={(e) => {
                  setValues("request_status", "pending_review");
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                {updateData.isData ? "Update" : "Save"}
              </button>
            )}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddRequest);
