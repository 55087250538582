import Cookies from "universal-cookie";
import { utcTimeToDate } from "./util";
const cookies = new Cookies();
let role = cookies.get("role");
export function createOrderObject(
  values,
  summary,
  user_id,
  selectedCard,
  payloadNonce
) {
  let customer_inf = values.customer_info;
  let billing_info = values.billing_info;

  let order_transaction = createOrderTransaction(
    customer_inf,
    summary,
    selectedCard
  );
  let customer_info = createCustomerInfo(customer_inf, billing_info);
  let order_payment_transaction = orderPaymentTransaction(
    values.payment_type,
    summary.total,
    selectedCard,
    payloadNonce
  );
  let internalnotes = values.internal_note?.trim()
    ? {
        order_id: 0,
        notes: values.internal_note ?? "",
        message : values.message ?? "",
        message_option : values.message_option ?? 0,
        created_by: user_id,
      }
    : undefined;
  let order = orderObject(values, summary, user_id);
  return {
    order_transaction,
    customer_info,
    order_payment_transaction,
    order,
    internalnotes,
  };
}

export function createOrderTransaction(customer_info, summary, selectedCard) {
  return {
    customer_id: customer_info.customer_id,
    customer_card_id: selectedCard?.id ?? 0,
    total_qty: summary.total_qty,
    sub_total: +summary.sub_total.toFixed(2),
    subscription_discount: 0,
    promocode_id: 0,
    promocode: "",
    promocode_type: "",
    discount_price: parseFloat(summary.discount_price).toFixed(2),
    delivery_charges: +summary.delivery_charges.toFixed(2),
    upcharge_amount: +summary.upcharge_amount.toFixed(2),
    tax: +summary.tax.toFixed(2),
    flower_total_amount: +summary.flower_total_amount.toFixed(2),
    petal_rewards_id: 0,
    petal_rewards_point_applied: 0,
    petal_rewards_point_amount: 0,
    earned_petal_rewards_point:0,
    total: +summary.total.toFixed(2),
    total_commission_amount: +summary.total_commission_amount.toFixed(2),
    gift_card_id: "",
    gift_card_detail: "",
    gift_card_amount: 0,
    order_from: 2,
  };
}

export function createCustomerInfo(customer_info, billing_info) {
  return {
    customer_first_name: customer_info.customer_first_name?.trim(),
    customer_last_name: customer_info.customer_last_name?.trim(),
    customer_billing_first_name: customer_info.customer_first_name?.trim(),
    customer_billing_last_name: customer_info.customer_last_name?.trim(),
    customer_billing_address_1: billing_info.customer_billing_address_1?.trim(),
    customer_billing_address_2: billing_info.customer_billing_address_2?.trim(),
    customer_billing_city: billing_info.customer_billing_city?.trim(),
    customer_billing_state: billing_info.customer_billing_state?.trim(),
    customer_billing_country: billing_info.customer_billing_country?.trim(),
    customer_billing_zipcode: billing_info.customer_billing_zipcode?.trim(),
    customer_phone: customer_info.customer_phone,
    customer_phone_country_code: customer_info.customer_phone_country_code,
    customer_email: customer_info.customer_email?.trim(),
    is_business_account: customer_info.is_business_account,
    customer_alt_phone: customer_info.customer_alt_phone,
    customer_alt_phone_country_code: customer_info.customer_alt_phone_country_code,
  };
}

export function orderPaymentTransaction(
  payment_type,
  total,
  selectedCard,
  payloadNonce
) {
  let tr;
  switch (payment_type) {
    case "credit_card":
      tr = {
        transaction_type: "credit_card",
        type: "debit",
        card_id: 0,
        card_type: payloadNonce.details.cardType,
        card_last_4_digits: payloadNonce.details.lastFour.toString(),
        paymentMethodNonce: payloadNonce.nonce,
        total_amount: +total.toFixed(2),
        description: "Payment with credit option",
        is_subscription_paymeny: 0,
      };
      break;
    case "saved_cards":
      tr = {
        transaction_type: "credit_card",
        type: "debit",
        card_id: selectedCard.id,
        card_last_4_digits: selectedCard.last_4number,
        total_amount: +total.toFixed(2),
        description: "Payment with credit option",
        card_token: selectedCard.card_id,
        card_type: selectedCard.card_type,
      };
      break;
    case "house_account":
      tr = {
        transaction_type: "house_account",
        type: "debit",
        card_id: 0,
        card_last_4_digits: "",
        total_amount: +total.toFixed(2),
        description: "Payment with house account option",
        card_token: "",
        card_type: "",
      };
      break;
    case "dove":
      tr = {
        transaction_type: "dove",
        type: "debit",
        card_id: 0,
        card_last_4_digits: "",
        total_amount: +total.toFixed(2),
        description: "Payment with dove account option",
        card_token: "",
        card_type: "",
      };
      break;
    case "replace_order":
      tr = {
        paymentMethodNonce: "",
        transaction_type: "credit_card",
        type: "debit",
        card_id: 0,
        card_last_4_digits: "",
        total_amount: 0,
        description: "",
        card_type: "",
      };
      break;

    default:
      break;
  }
  return [tr];
}

export function orderObject(values, summary, user_id) {
  // debugger
  let delivery_information = values.delivery_information;
  let recipients = values.recipients;
  let customOrderFlag = 0;

  let receipient = recipients.map((rec) => {
    let products = rec.products.map((prod) => {
      customOrderFlag = !customOrderFlag ? prod?.custom_product_flag : customOrderFlag
      return {
        order_id: 0,
        order_recipients_id: rec.recipient_id,
        product_id: prod.id,
        product_type: 0,
        qty: prod.qty,
        unit_price: +prod.selectedVariant.price.toFixed(2),
        total_price: +prod.total_price.toFixed(2) ?? 0,
        promocode: "",
        discount_price: 0,
        commission_price: +prod.selectedVariant.commission.toFixed(2),
        total_commission_price: +(
          prod.selectedVariant.commission * prod.qty
        ).toFixed(2),
        product_name: prod.title?.trim() ?? "",
        variation_id: prod.selectedVariant.id ?? 0,
        variation_image: prod.selectedVariant.image ?? "",
        variation_name: prod.selectedVariant.title?.trim() ?? "",
        custom_product_flag: prod?.custom_product_flag ?? 0,
      };
    });

    let addons = rec.addons.map((prod) => {
      return {
        order_id: 0,
        order_recipients_id: rec.recipient_id,
        product_id: prod.id,
        product_type: 1,
        qty: prod.qty,
        unit_price: +prod.selectedVariant.price.toFixed(2),
        total_price: +prod.total_price.toFixed(2) ?? 0,
        promocode: "",
        discount_price: 0,
        commission_price: +prod.selectedVariant.commission.toFixed(2),
        total_commission_price: +(
          prod.selectedVariant.commission * prod.qty
        ).toFixed(2),
        product_name: prod.title?.trim() ?? "",
        variation_id: prod.selectedVariant.id ?? 0,
        variation_image: prod.selectedVariant.image ?? "",
        variation_name: prod.selectedVariant.title?.trim() ?? "",
      };
    });
    return {
      recipients_id: rec.recipient_id ?? 0,
      order_id: 0,
      first_name: rec.first_name?.trim() ?? "",
      last_name: rec.last_name?.trim() ?? "",
      address_type: delivery_information.address_type ?? "",
      address_type_id: delivery_information.address_type_id ?? 0,
      attn: delivery_information.attn?.trim() ?? "",
      address_line_1: delivery_information.address_line_1?.trim() ?? "",
      address_line_2: delivery_information.address_line_2?.trim() ?? "",
      phone: delivery_information.phone.toString() ?? "",
      phone_country_code: delivery_information.phone_country_code.toString() ?? "+1",
      alt_phone: delivery_information?.alt_phone?.toString() ?? "",
      alt_phone_country_code: delivery_information.alt_phone_country_code.toString() ?? "+1",
      city: delivery_information.city?.trim() ?? "",
      state: delivery_information.state?.trim() ?? "",
      country: delivery_information.country?.trim() ?? "",
      zipcode: delivery_information.zipcode?.trim() ?? "",
      latitude: "",
      longitude: "",
      special_note: "",
      whats_the_occasion: rec.whats_the_occasion ?? "",
      whats_the_occasion_id: rec.whats_the_occasion_id ?? 0,
      greetings_on_card: rec.greetings_on_card?.trim() ?? "",
      card_signature: rec.card_signature?.trim() ?? "",
      video_url: "",
      video_message: "",
      video_message_pass_code: "",
      order_from: 2,
      recipient_product: [...products, ...addons],
    };
  });
  let order = {
    // is_custom_order: values.custom_order,
    is_custom_order: customOrderFlag ?? 0,
    order_type_identifier: values.order_type_identifier,
    parent_order_id: values.parent_order_id ?? 0,
    is_subscribe_order: values.is_subscribe_order ?? 0,
    frequency: delivery_information.frequency ?? null,
    csr_id: user_id,
    order_type: delivery_information.order_type,
    store_id:delivery_information.storeId,
    delivery_date_time: delivery_information.delivery_date_time,
    delivery_time_type: delivery_information.delivery_time_type,
    source: "csr",
    designer_id: 0,
    actual_delivered_time: delivery_information.actual_delivered_time,
    recipient_signature_or_picture: "",
    driver_instruction: delivery_information.driver_instruction,
    delivery_date: utcTimeToDate(delivery_information.delivery_date_time),
    delivery_type: delivery_information.delivery_type,
    delivery_type_id: delivery_information.delivery_type_id,
    delivered_time_id:
      delivery_information.delivery_time_id === -1
        ? 0
        : delivery_information.delivery_time_id,
    delivered_time: delivery_information.delivery_time,
    is_custom_delivery_matrix:
      delivery_information.delivery_time_id === -1 ? 1 : 0,
    custom_delivery_time: "",
    custom_delivery_time_ampm: "",
    custom_delivery_time_before_after: "",
    custom_delivery_time_amount: "",
    shopcode: delivery_information.shop_code,
    external_order_number: delivery_information.external_order_number,
    designer_instruction: delivery_information.designer_instruction,
    total_qty: summary.total_qty,
    sub_total: +summary.sub_total.toFixed(2),
    promocode_id: "",
    promocode: "",
    promocode_type: "",
    custom_reason_for_discount: values.discount.discount_reason,
    discount_percentage: values.discount.discount_percentage,
    // discount_price: values.discount.discount_price,
    discount_price: parseFloat(summary.discount_price).toFixed(2),
    // upcharge_id: delivery_information.upcharge_id,
    upcharge_id: summary.upcharge_amount >0 ? delivery_information.upcharge_id : 0,
    // upcharge_amount: delivery_information.upcharge_amount,
    upcharge_amount: +summary.upcharge_amount,
    delivery_charges: +summary.delivery_charges.toFixed(2),
    tax: +summary.tax.toFixed(2),
    tax_id: values.tax_id,
    flower_total_amount: +summary.flower_total_amount.toFixed(2),
    petal_rewards_id: 0,
    petal_rewards_point_applied: 0,
    petal_rewards_point_amount: 0,
    total: +summary.total.toFixed(2),
    gift_card_id: 0,
    gift_card_detail: "",
    gift_card_amount: 0,
    earned_petal_rewards_point:0,
    petal_rewards_earn_amount: 0,
    total_commission_amount:
      values.payment_type === "house_account"
        ? 0
        : +summary.total_commission_amount.toFixed(2),
    internal_notes: "",
    order_status: "",
    created_by: user_id,
    is_fraud_order: 0,
    not_available_at_home_key: "",
    not_available_at_home_option: "",
    is_local_order: delivery_information.order_type
      ? 1
      : delivery_information.is_local
      ? 1
      : 0,
    receipient: receipient,
    burq_quote_id: delivery_information.burq_quote_id ?? "",
    burq_quote_expires: delivery_information.burq_quote_expires ?? "",
  };

  return [order];
}
