import { MODULES, STATUS_FILTER } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import React, { useContext, useEffect, useState } from "react";

// Components
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import Header from "./../../../Components/Header/Header";
import ReactTable from "./../../../Components/Table/Table";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";

// Services
import RecipeApi from "./../../../Services/Recipe/Recipe";
import UtilsApi from "../../../Services/Utils/Utils";
import { updateStatus } from "../../../api/utils";
import { getRecipeList, deleteRecipe } from "../../../api/recipe";

//scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";

// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";

import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import ActivatedIcon from "./../../../Assets/images/activated.svg";

const RecipeList = (props) => {
  const history = useHistory();
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteRecipeId, setDeleteRecipeId] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [
    statusChangeProductRecipeId,
    setStatusChangeProductRecipeId,
  ] = useState(false);
  const [statusOfProductRecipeId, setStatusOfProductRecipeId] = useState();
  const statusList = STATUS_FILTER;

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const recipeService = new RecipeApi();
  const utilsService = new UtilsApi();

  const fetchData = async ({
    pageIndex,
    pageSize,
    filters,
    globalFilter,
    sortBy,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    let recipeAccess = getAccessByModuleName(modules, MODULES.RECIPE);
    if (recipeAccess && recipeAccess.permissions.read_access) {
      // const response = await recipeService.getRecipeList(postData);
      const response = await getRecipeList(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   setData(data);
  // }, [data]);

  const handleDeleteRecipeClick = async (event, id) => {
    event.preventDefault();

    setDeleteRecipeId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteRecipe = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await recipeService.deleteRecipe(deleteRecipeId);
    const response = await deleteRecipe(deleteRecipeId);

    if (response && response.status === 200) {
      setDeleteRecipeId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleManageRecipeClick = async (event, type = "add", recipe = {}) => {
    if (type === "add") {
      event.preventDefault();
      history.push(`/product-catalog/recipe/add-recipe`);
    } else {
      let id = recipe.id;
      event.preventDefault();
      props.history.push(`/product-catalog/recipe/edit-recipe/${id}`);
    }
  };

  const handleStatusProductRecipeClick = async (event, id, status) => {
    event.preventDefault();

    setStatusChangeProductRecipeId(id);
    setStatusOfProductRecipeId(status);
    setShowStatusModal(true);
  };

  const handleProductRecipeStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfProductRecipeId === 1 ? 0 : 1,
      id: statusChangeProductRecipeId,
      table: "product_recipes",
      auditLogEntries:true
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);

    if (response && response.status === 200) {
      setStatusChangeProductRecipeId(null);
      setStatusOfProductRecipeId(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.RECIPE);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  let recipeAccess = getAccessByModuleName(modules, MODULES.RECIPE);
  const columns = React.useMemo(
    () => [
      {
        Header: "Recipe Name",
        accessor: "recipe_name",
        isVisible: true,
      },
      {
        Header: "Ingredients",
        accessor: "ingredient_display_name",
        isVisible: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className={`link cursor-pointer`}>
              {recipeAccess && recipeAccess.permissions.write_access && (
                <img
                  onClick={(e) =>
                    handleStatusProductRecipeClick(
                      e,
                      value,
                      row.original.status
                    )
                  }
                  className={`${styles.searchIcon} mr-3`}
                  src={ActivatedIcon}
                  alt=""
                />
              )}
              {recipeAccess && recipeAccess.permissions.delete_access && (
                <img
                  onClick={(e) => handleDeleteRecipeClick(e, value)}
                  className={`${styles.searchIcon} mr-3`}
                  src={DeleteIcon}
                  alt=""
                />
              )}
              {recipeAccess && recipeAccess.permissions.write_access && (
                <img
                  onClick={(e) =>
                    handleManageRecipeClick(e, "edit", row.original)
                  }
                  className={`${styles.searchIcon} mr-3`}
                  src={EditIcon}
                  alt=""
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header
        title="Recipe List"
        hasAddButton={access.write_access}
        handleAddClick={handleManageRecipeClick}
      />
      <div id="MainContent" className="main-content" />
      <React.Fragment>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </React.Fragment>

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Recipe"
        message="Are you sure want to delete this recipe?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteRecipeId(null);
        }}
        handleSubmitClick={handleDeleteRecipe}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Recipe"}
        message={"recipe?"}
        buttonLabel={statusOfProductRecipeId ? "Inactivate" : "Activate"}
        statusFlag={statusOfProductRecipeId}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusChangeProductRecipeId(null);
          setStatusOfProductRecipeId(null);
        }}
        handleSubmitClick={handleProductRecipeStatus}
      />
    </React.Fragment>
  );
};

export default withRouter(RecipeList);
