import Header from "Components/Header/Header";
import { AppContext } from "Context/AppContext";
import { MODULES, STATUS_FILTER } from "Helpers/Constants";
import { getAccessByModuleName } from "Routes/Routes";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import RecipeSettingsApi from "../../../Services/RecipeSettings/RecipeSettings";
import Tabs from "Components/InventoryManagement/Tabs";
import TabPane from "Components/InventoryManagement/Tabpane";
import ReactTable from "../../../Components/Table/Table";
import styles from "./index.module.scss";
import hidden from "../../../Assets/images/default.svg";
import update from "../../../Assets/images/update.svg";
import {
  getRecipeRawMaterial,
  updateRetailPrice,
  updateWholeSalePrice,
} from "api/recipeSetting";
import { Input } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

const Pricing = (props) => {
  const recipeSettingsService = new RecipeSettingsApi();

  const [access, setAccess] = useState({});
  const { modules, showToastMessage, setIsLoading } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [active, setActive] = useState("");
  const [currenttTab,setCurrentTab] = useState("")
  const [resetPagination,setResetPagination] = useState(false)
  const [firtsTabData, setFirstTabData] = useState(true)

  const [defaultFilter, setDefaultFilter] = useState({
    currentPage: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "id",
      },
    ],
    filters: [],
  });
  const statusList = STATUS_FILTER;

  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.PRICING);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }

  //call list type API
  const listType = async ()=>{
    const postData = {
      currentPage: 0,
      itemsPerPage: 200
    };
    try {
      const type = await recipeSettingsService.recipeTypeList(postData);
      if (type && type.status === 200) {
        return type.data.data.rows
      } else {
        showToastMessage(undefined, type.data.message, "error", false);
      return false
      }
    } catch (error) {
      showToastMessage(undefined, error.message, "error", false);
      return false
    }
  } 

  //change tab and display data accordingly
  const changeTab = async (name) => {
    setCurrentTab(name)
    setActive(name);
    setInitState(false);
    setResetPagination(true)
    const postData = {
      ...defaultFilter,
      currentPage: 0,
      pageIndex: 0,
      pageSize: 10,
      list_type : name,
    };
    await fetchData(postData);
  };

  const [checkInitState, setInitState] = useState(false);

  const [recipeType, setRecipeType] = useState([]);

  //fetch data
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    list_type
   }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    let firstTab = ""
    if(firtsTabData){
      const type = await listType()
      firstTab = type[0].title
      setCurrentTab(type[0].title)
    }
    const postData = {
      ...defaultFilter,
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      list_type : list_type ? list_type : (currenttTab ? currenttTab : firstTab)
    };

    //get recipe material data as per the selected tab
    const response = await getRecipeRawMaterial(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setResetPagination(false)
      setFirstTabData(false)
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      setResetPagination(false)
      setFirstTabData(false)
      props.history.push("/profile");
    } else {
      setResetPagination(false)
      setFirstTabData(false)
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  useEffect(async () => {
    // const postData = {
    //   currentPage: 0,
    //   itemsPerPage: 200
    // };
    const response = await listType()
    if (response) {
      setInitState(true);
      setRecipeType(response);
    } else {
      setInitState(false);
    }
    // const firstTab = response.data.data.rows[0].title;
    // setCurrentTab(firstTab)
    // const sendBody = {
    //   ...defaultFilter,
    //   list_type:firstTab
    // };
    // await fetchData(sendBody);
  }, []);

  //update particular price 
  const updateMyData = async (rowIndex, columnId, value, other = {}) => {
    const data = other.data.find((elem, idx) => idx == rowIndex);

    const postData = {
      id: data.id,
      value: value,
    };

    if(access.write_access){
      await updatePrice(columnId, postData);
      setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
    }else{
      showToastMessage(undefined, "You're not authorized to update price", "error", false);
    }
  };

  const updatePrice = async (key, data) => {
    try {
      let response;
      setIsLoading(true);
      if (key === "whole_sale_price") {
        response = await updateWholeSalePrice(data.id, {
          whole_sale_price: data.value,
        });
      } else if (key === "retail_price") {
        response = await updateRetailPrice(data.id, {
          retail_price: data.value,
        });
      }
      if (response && response.status === 200) {
        console.log("response.status: ", response.status);
        showToastMessage(undefined, response.data.message, "success", false);
        setIsLoading(false);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
        setIsLoading(false);
      }
    } catch (error) {
      showToastMessage(
        undefined,
        error.response.data.data.message,
        "error",
        false
      );
      setIsLoading(false);
    }
  };

  // Create an editable cell
  const EditableCell = (props) => {
    const {
      row: { index },
      value: initialValue,
      column: { id },
      updateMyData,
      ...other
    } = props;

    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [isEdit, setEdit] = useState(false);

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const makeEditableHandler = () => {
      setEdit(true);
    };

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onClickSaveHandler = () => {
      if (initialValue != value) {
        updateMyData(index, id, value, other);
      }
      if (value === "" || value == null) {
        setValue(initialValue);
        showToastMessage(
          undefined,
          `Inverntry value can't be null`,
          "error",
          false
        );
      }
      setEdit(false);
    };
    return (
      <div className="d-flex justify-content-start align-items-center">
        <div>
          <div className="d-flex align-items-center">
            {/* <span style={{paddingBottom:3, paddingRight:1, fontSize:19}}>$ </span> */}
            <Input
              disableUnderline={true}
              type="number"
              value={value ?? initialValue}
              onChange={onChange}
              onClick={makeEditableHandler}
              inputProps={{
                style:{padding:"6px 0 7px"}
              }}
              startAdornment={
                <InputAdornment position="start">
                  <span style={{fontSize:20, lineHeight:"2px"}}>$</span>
                </InputAdornment>
              }
            />
            <span>
              {isEdit && (
                <img
                  style={{ cursor: "pointer", width: 24, height: 24 }}
                  src={update}
                  alt="action"
                  onClick={onClickSaveHandler}
                />
              )}
              {!isEdit && (
                <img
                  src={hidden}
                  alt="action"
                  style={{ width: 24, height: 24 }}
                />
              )}
            </span>
          </div>
          <hr style={{ padding: 0, margin:0 }} />
        </div>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.ImageWapper}>
              { value === "" ||
                value === null ||
                value === "null" ||
                value === undefined ? (
                <div className={styles.emptyImage}>
                  <span>No Image</span>
                </div>
              ) : (
                <div>
                  <img
                    src={`${value}?w=70&q=75&auto=compress,format`}
                    alt="recipe image"
                  />
                </div>
              )}
            </div>
          )
        },
      },
      {
        Header: "Raw Material Name",
        accessor: "title",
        isVisible: true,
        placeholder: "Search By Raw Material Name",
      },
      {
        Header: "Unit",
        accessor: "product_recipe_unit",
        isVisible: true,
        placeholder: "Search By Unit",
        Cell: ({ value, row, column }) => {
          return <div>{value?.title ?? "-"}</div>;
        },
      },
      {
        Header: "Whole Sale Price",
        accessor: "whole_sale_price",
        isVisible: true,
        disableFilters: true,
        Cell: EditableCell,
      },
      {
        Header: "Retail Price",
        accessor: "retail_price",
        isVisible: true,
        disableFilters: true,
        Cell: EditableCell,
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={`${styles.status} d-flex justify-content-start`}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
    ],
    [data]
  );

  return (
    <>
      <Header title="Pricing" />
      <div>
        <div>
          <Tabs
            active={active}
            changeTab={changeTab}
            setActive={setActive}
            checkInit={checkInitState}
          >
            {recipeType.map((type, idx) => {
              return <TabPane name={type.title} key={type.id} />;
            })}
          </Tabs>
        </div>
        <div className="mt-5">
          <ReactTable
            columns={columns}
            data={data}
            expandedRows={true}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            updateMyData={updateMyData}
            resetPagination={resetPagination}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(Pricing);
