import Cookies from "universal-cookie";

export function orderCaculation(orderCalculationState) {
  let orderState = { ...orderCalculationState };
  let total = 0;
  let sub_total = 0;
  let flower_total_amount = 0;
  let total_commission_amount = 0;
  let tax = 0;
  let total_qty = 0;
  let upcharge_amount =
    orderState?.order_type_identifier === 1
      ? 0
      : orderState.order.upcharge_amount ?? 0;
  let discount_price = orderState.order.discount_price ?? 0;
  let discount_percentage = orderState.order.discount_percentage ?? 0;
  let delivery_charges =
    orderState?.order_type_identifier === 1
      ? 0
      : orderState.order.delivery_charges ?? 0;
  let add_fund = orderState.order.add_fund ?? 0;
  let refund = orderState.order.refund ?? 0;
  let mainTotal = orderState.order.mainTotal ?? 0;
  let subscription_discount =
    orderState.order_transaction.subscription_discount ?? 0 ?? 0;
  //   Calculate Sub total,Flowe total,total commition ,total qty calculation
  orderState.recipents.forEach((recipentData) => {
    recipentData.added_product.forEach((productData) => {
      flower_total_amount = productData.total_price + flower_total_amount;
      sub_total = productData.total_price + sub_total;
      total_commission_amount =
        productData.selectedVariant.commission * productData.qty +
        total_commission_amount;
      total_qty = total_qty + productData.qty;
    });
    recipentData.addedAddons.forEach((addonData) => {
      flower_total_amount = addonData.total_price + flower_total_amount;
      sub_total = addonData.total_price + sub_total;
      total_commission_amount =
        addonData.selectedVariant.commission * addonData.qty +
        total_commission_amount;
      total_qty = total_qty + addonData.qty;
    });
  });
  discount_price =
    discount_percentage === 0
      ? discount_price
      : (sub_total * discount_percentage) / 100;
  //   Tax calcullation
  tax =
    orderState.order.tax_id === ""
      ? ((sub_total - discount_price - subscription_discount) / 100) * 9.5
      : 0;
  total =
    tax +
    sub_total +
    delivery_charges +
    upcharge_amount -
    discount_price -
    subscription_discount;
  if (mainTotal > total) {
    let newrefund = mainTotal - total;
    refund = newrefund;
    add_fund = 0;
  } else if (mainTotal === total) {
    add_fund = 0;
    refund = 0;
  } else {
    let newAddfund = total - mainTotal;
    add_fund = newAddfund;
    refund = 0;
  }
  return {
    ...orderState,
    order: {
      ...orderState.order,
      total,
      discount_price,
      delivery_charges,
      upcharge_amount,
      total_qty,
      tax,
      total_commission_amount,
      flower_total_amount,
      sub_total,
      add_fund: add_fund.toFixed(2),
      refund: refund.toFixed(2),
    },
    order_transaction: {
      ...orderState.order_transaction,
      total,
      discount_price,
      delivery_charges,
      upcharge_amount,
      total_qty,
      tax,
      total_commission_amount,
      flower_total_amount,
      sub_total,
    },
  };
}

export function calculatePetalReward(amount) {
  // If coustumer id is 0 then do not calculate petal reward
  const cookie = new Cookies();
  var getpetalRewardConfig = cookie.get("petalrewardconfig");
  if (
    getpetalRewardConfig &&
    getpetalRewardConfig !== null &&
    getpetalRewardConfig !== undefined &&
    getpetalRewardConfig !== ""
  ) {
    let configAmount = getpetalRewardConfig.amount;
    let configPoint = getpetalRewardConfig.points;
    let evalPoints = (amount * configPoint) / configAmount;
    return evalPoints ? evalPoints.toFixed(0) : 0;
  } else {
    return 0;
  }
}

export function calcuateSummary(formik) {
  let total = 0;
  let sub_total = 0;
  let flower_total_amount = 0;
  let total_commission_amount = 0;
  let tax = 0;
  let total_qty = 0;
  let bonus_point_amount =
    formik.values?.petal_reward?.petal_rewards_point_amount || 0;
  let upcharge_amount =
    formik.values?.order_type_identifier === 1
      ? 0
      : formik.values.delivery_information.upcharge_amount;
  let discount_price = formik.values.discount.discount_price ?? 0;
  let discount_percentage = formik.values.discount.discount_percentage ?? 0;
  let delivery_charges =
    formik.values?.order_type_identifier === 1
      ? 0
      : formik.values.delivery_information.charge ? formik.values.delivery_information.charge : 0;
  let add_fund = formik.values.add_fund;
  let refund = formik.values.refund ?? 0;
  let mainTotal = formik.values.mainTotal ?? 0;
  let subscription_discount = formik.values.subscription_discount;
  console.log(formik.values.payment_type)

  formik?.values?.recipients?.forEach((recipentData) => {
    recipentData?.products?.forEach((productData) => {
      flower_total_amount = productData.total_price + flower_total_amount;
      sub_total = productData.total_price + sub_total;
      total_commission_amount =
        productData.selectedVariant.commission * productData.qty +
        total_commission_amount;
      total_qty = total_qty + productData.qty;
    });
    recipentData?.addons?.forEach((addonData) => {
      flower_total_amount = addonData.total_price + flower_total_amount;
      sub_total = addonData.total_price + sub_total;
      total_commission_amount =
        addonData.selectedVariant.commission * addonData.qty +
        total_commission_amount;
      total_qty = total_qty + addonData.qty;
    });
  });
  discount_price =
    discount_percentage === 0
      ? discount_price
      : (sub_total * discount_percentage) / 100;
  //   Tax calcullation
  discount_price = typeof discount_price === 'string' ?   parseFloat(parseFloat(discount_price).toFixed(2)) :  parseFloat(discount_price?.toFixed(2));
  tax =
    formik.values.tax_id === "" && formik.values.payment_type !== "house_account"
      ? ((sub_total -
          discount_price -
          subscription_discount -
          bonus_point_amount) /
          100) *
        9.5
      : 0;
  total =
    tax +
    sub_total +
    delivery_charges +
    upcharge_amount -
    discount_price -
    subscription_discount -
    bonus_point_amount;
  if (mainTotal > total) {
    let newrefund = mainTotal - total;
    refund = newrefund;
    add_fund = 0;
  } else if (mainTotal === total) {
    add_fund = 0;
    refund = 0;
  } else {
    let newAddfund = total - mainTotal;
    add_fund = newAddfund;
    refund = 0;
  }

  return {
    total,
    discount_price,
    delivery_charges,
    upcharge_amount,
    total_qty,
    tax,
    total_commission_amount,
    flower_total_amount,
    sub_total,
    add_fund: +add_fund.toFixed(2),
    refund: +refund.toFixed(2),
    subscription_discount,
  };
}
