import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
// Component
import Text from "Components/FormControl/Text/Text";
import { useFormik, useFormikContext } from "formik";
import React from "react";
import { useEffect } from "react";
import { useOrderAttributeProvider } from "../../context/order";
// SCSS
import styles from "./OrderDiscount.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderDiscount = ({
  showModal,
  pageTitle,
  submitStatus,
  handleCancelClick,
}) => {
  const { summary } = useOrderAttributeProvider();
  const mainFormik = useFormikContext();

  const formik = useFormik({
    initialValues: {
      discount_type:"0",
      discount_reason: "",
      discount_percentage: 0,
      discount_price: 0,
    },
    onSubmit: (values) => {
      mainFormik.setValues((v) => ({
        ...v,
        discount: {
          ...values,
        },
      }));
      handleCancelClick();
    },
    validate: (values) => {
      const errors = {};

      if (values.discount_percentage > 100 && formik.values.discount_type === "1") {
        errors.discount_percentage = "Invalid discount percent";
      }
      if (values.discount_price > summary?.sub_total && formik.values.discount_type === "0") {
        errors.discount_price =
          "Discount price cannot be greater than total value";
      }
      return errors;
    },
  });

  useEffect(() => {
    formik.setValues(v=>{
    return {
      ...mainFormik.values.discount
    }
    })
    formik.validateForm();
  }, [summary]);
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <form onSubmit={formik.handleSubmit}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {pageTitle}
              </h5>
              <div className="row ml-3 mr-3">
                <div className="row">
                  <div className={`col-md-6 col-xs-12`}>
                    <RadioBox
                      label="$"
                      value="0"
                      selectedValue={formik.values.discount_type}
                      required={true}
                      submitStatus={submitStatus}
                      onChange={(data) => {
                        
                        formik.setValues((v) => {
                          return {
                            ...v,
                            discount_type:data,
                            discount_price: formik.values.discount_percentage,
                            discount_percentage: 0,
                          };
                        });
                      }}
                    />
                    <RadioBox
                      label="%"
                      value="1"
                      selectedValue={formik.values.discount_type}
                      required={true}
                      submitStatus={submitStatus}
                      onChange={(data) => {
                        
                        formik.setValues((v) => {
                          return {
                            ...v,
                            discount_type:data,
                            discount_price: 0,
                            discount_percentage:
                              formik.values.discount_price > 100
                                ? 0
                                : formik.values.discount_price,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className={`col-md-6 col-xs-12`}>
                    <Text
                      type="text"
                      label="Discount"
                      value={
                        formik.values.discount_type === "1"
                          ? formik.values.discount_percentage || ""
                          : formik.values.discount_price || ""
                      }
                      required={true}
                      submitStatus={submitStatus}
                      onChange={(value) => {
                        if (/^[0-9]*(\.[0-9]*)?$/.test(value)) {
                          let dp = 0;
                          let dc = 0;
                          if (formik.values.discount_type === "0") {
                            dp = value;
                          } else {
                            dc = value;
                          }

                          formik.setValues((v) => {
                            return {
                              ...v,
                              discount_price: dp,
                              discount_percentage: dc,
                            };
                          });
                        }
                      }}
                    />
                    {
                      <p className="invalid-feedback d-block text-left">
                        {formik?.errors?.discount_percentage ||
                          formik?.errors?.discount_price}
                      </p>
                    }
                  </div>
                </div>
                <div className={`col-md-12 col-xs-12 mb-4`}>
                  <Text
                    type="text"
                    label="Reason"
                    id="discount_reason"
                    value={formik.values.discount_reason}
                    onChangeWithEvent={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.discount_reason}
                    touched={formik.touched.discount_reason}
                    required={true}
                    submitStatus={submitStatus}
                  />
                </div>
              </div>
              <button
                type="submit"
                data-dismiss="modal"
                // onClick={(e) => {
                //   if (!disscountError) {
                //     applyDiscount(e);
                //   }
                // }}
                className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
              >
                Save
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderDiscount;
