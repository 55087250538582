import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../../Context/AppContext";
import Select from "../../../../Components/FormControl/Select/Select";
import AddSendData from "./addSendData";
import { useFormik } from "formik";
import {
  getRowMaterialsList,
  addNewSendResource,
} from "api/inventoryProduct";
import Header from "Components/Header/Header";
import { storeList } from "api/stores";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { getAccessByModuleName } from "Routes/Routes";
import { MODULES } from "Helpers/Constants";

const AddSendResource = (props) => {
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [showErrors, setShowErrors] = useState(false);

  const [rawMaterial, setMaterials] = useState([]);
  const [data, setData] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  //Check accessiblity
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.SEND_RESOURCES);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }

  //It used globly to set initial value when needed
  const initialValue = [
    {
      id: "",
      source_inventory_product_id: 0,
      inventory_available_quantity: 0,
      raw_matrial_type: "",
      raw_matrial_unit: "",
      raw_material_price: 0,
      filled_quantity: 0,
      inventory_raw_material_id: "",
      inventory_raw_material: "",
    },
  ];

  //Intialize formik
  const formik = useFormik({
    initialValues: {
      destination_store_id: 0,
      request_status: "",
      inventory_request_resource_ingredients: initialValue,
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validate: (values) => {
      if (!values.destination_store_id) {
        setShowErrors(true);
      }
      values.inventory_request_resource_ingredients.forEach((value, idx) => {
        if (
          (value.inventory_raw_material_id && !value.filled_quantity) ||
          !value.filled_quantity ||
          !value.inventory_raw_material_id
        ) {
          setShowErrors(true);
        } else if (
          value.filled_quantity &&
          !value.inventory_raw_material_id
        ) {
          setShowErrors(true);
        } else {
          setShowErrors(false);
        }
      });
    },
  });

  //update values by key names
  const setValues = async (index, value) => {
    await formik.setValues({ ...formik.values, [index]: value });
  };

  //Get all list store which are availabe int the system
  useEffect(async () => {
    const selectedStore = localStorage.getItem("selectedStore");
    await getRowMaterialsData(selectedStore);
    setShowErrors(false);
    const getStoreList = await storeList();
    if (getStoreList && getStoreList.status === 200) {
      setStoresList(
        getStoreList.data.data.rows.filter((s) => s.id != selectedStore)
      );
    } else {
      showToastMessage(undefined, getStoreList.data.message, "error", false);
    }
  }, []);

  //Manage the final submit to edit detail or add new record
  const handleSubmit = async (value) => {
    //check duplicates selection of raw materials
    const inventory_raw_material =
      value.inventory_request_resource_ingredients?.map(
        (v) => v.inventory_raw_material_id
      );
    let duplicatesMaterials = [];
    let isDuplicate = false;

    //find duplicates raw materials if any
    inventory_raw_material?.some((v, idx) => {
      if (inventory_raw_material?.indexOf(v) != idx) {
        isDuplicate = true;
        const duplicateData =
          value.inventory_request_resource_ingredients?.find(
            (d) => d.inventory_raw_material_id === v
          );
        duplicatesMaterials.push(duplicateData.inventory_raw_material);
      }
    });

    if (isDuplicate) {
      showToastMessage(
        undefined,
        `Raw materials ${duplicatesMaterials.join(
          ", "
        )} are selected multiple times, please remove one of them.`,
        "error",
        false
      );
      return;
    } else if (showErrors) {
      showToastMessage(
        undefined,
        "Please add all ingredients data.",
        "error",
        false
      );
      return;
    }
    let validationFlag = false;
    value?.inventory_request_resource_ingredients.forEach((val, idx) => {
      if (!val.inventory_raw_material_id) {
        validationFlag = true;
      }
      if (!val.filled_quantity) {
        validationFlag = true;
      }
    });
    if (validationFlag) {
      showToastMessage(
        undefined,
        "Please add all ingredients data",
        "error",
        false
      );
      return;
    }
    
    try {
      setIsLoading(true);
      const response = await addNewSendResource(value);
      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        setIsLoading(false);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      showToastMessage(undefined, error.message, "error", false);
    }
    history.push(`/inventory-management/send-resources/`);
  };

  //Handle raw materials selection and values as per the selected materials
  const onChangeHandler = (type, value, position = "") => {
    if (type === "requestInventory") {
      const selectedData = data.find(
        (da) => da.product_recipe_raw_material.id === value
      );
      const newdata = {
        inventory_raw_material_id: value,
        inventory_available_quantity: selectedData?.quantity,
        raw_material_price:
          selectedData?.product_recipe_raw_material?.whole_sale_price,
        raw_matrial_unit:
          selectedData?.product_recipe_raw_material?.product_recipe_unit
            ?.title ?? "",
        raw_matrial_type:
          selectedData?.product_recipe_raw_material?.product_recipe_type
            ?.title ?? "",
        source_inventory_product_id: selectedData?.id,
        filled_quantity: 0,
        inventory_raw_material:
          selectedData?.product_recipe_raw_material?.title,
      };
      formik.values.inventory_request_resource_ingredients[position] = newdata;
      const newRequestedValue = [
        ...formik.values.inventory_request_resource_ingredients,
      ];
      setValues("inventory_request_resource_ingredients", newRequestedValue);
    }
  };

  //It handles request quantity
  const onChangeQtyHandler = (key, value, position) => {
    const prevValue = formik.values.inventory_request_resource_ingredients;
    let newValue = prevValue[position];
    newValue.filled_quantity = value;
    formik.values.inventory_request_resource_ingredients[position] = newValue;
    setValues("inventory_request_resource_ingredients", [
      ...formik.values.inventory_request_resource_ingredients,
    ]);
  };

  //This function will handdle add new row
  const addNewRow = () => {
    let data = formik.values.inventory_request_resource_ingredients;
    data.push(initialValue[0]);
    formik.setValues((value) => {
      return {
        ...value,
        inventory_request_resource_ingredients: [...data],
      };
    });
  };

  //delete request resource row run time from row data
  const deleteRow = (values) => {
    formik.setValues((value) => {
      return {
        ...value,
        inventory_request_resource_ingredients:
          value.inventory_request_resource_ingredients.filter(
            (s) => s !== values
          ),
      };
    });
  };

  //Handle store selection and as per selected store list all active inventory data
  const getRowMaterialsData = async (storeId) => {
    setLoading(true);
    const response = await getRowMaterialsList(storeId);
    let temp = [];
    if (response && response.status === 200) {
      setData(response.data.data);
      response.data.data.map((raw) => {
        temp.push({
          id: raw.product_recipe_raw_material.id,
          title: raw.product_recipe_raw_material.title,
        });
        setMaterials(temp);
      });
      temp = [];
      await formik.setValues({
        destination_store_id: storeId,
        request_status: "",
        inventory_request_resource_ingredients: initialValue,
      });
      setLoading(false);
    } else {
      setLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  //only use in update case and set requested value to update
  // useEffect(async () => {
  //   setMaterials([]);
  //     await formik.setValues({
  //       request_status: "",
  //       destination_store_id: "",
  //       inventory_request_resource_ingredients: initialValue,
  //     });
  // }, []);

  return (
    <React.Fragment>
      <Header title="Add Send Resource" />
      <div id="MainContent" className="main-content">
        <form
          onSubmit={(e) => {
            formik.handleSubmit(e);
          }}
        >
          <div className="row d-flex justify-content-center">
            <div className="col-md-5 col-xs-12 text-left">
              <Select
                placeholder={"Select Destination Store"}
                options={storesList}
                onChange={(store) => {
                  // getRowMaterialsData(store);
                  setValues("destination_store_id", store);
                }}
                value={formik.values.destination_store_id}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <table className="rt-table table border-bottom">
              <thead className="rt-thead -header">
                <tr className="rt-tr">
                  <th className="rt-th rt-resizable-header">Raw Material</th>
                  <th className="rt-th rt-resizable-header">Unit</th>
                  <th className="rt-th rt-resizable-header">Available Qty</th>
                  <th className="rt-th rt-resizable-header">Price</th>
                  <th className="rt-th rt-resizable-header">Fill Qty</th>
                  <th className="rt-th rt-resizable-header">Action</th>
                </tr>
              </thead>
              <tbody className="rt-tbody">
                <AddSendData
                  requestInventory={
                    formik.values.inventory_request_resource_ingredients
                  }
                  add={addNewRow}
                  delete={deleteRow.bind()}
                  rawMaterials={rawMaterial}
                  onChange={onChangeHandler}
                  setValues={onChangeQtyHandler}
                  isLoading={isLoading}
                />
              </tbody>
            </table>
          </div>
          <div class={`text-center p-5`}>
            <button
                type="submit"
                onClick={(e) => {
                  setValues("request_status", "filled");
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Save
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddSendResource);
