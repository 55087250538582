import { callApi, callGetApiv2, callPostApiv2, callPutApiv2 } from "./api";

export async function getCheckZipcodeAvability(zip_code) {
  try {
    const data = await callApi({
      url: `store-settings/zipcode/check-availability/${zip_code}`,
      method: "GET",
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOrderList(data) {
  return callPostApiv2("orders/order-lookup", data);
}

export async function getOnfleetOrderList(data) {
  return callPostApiv2("orders/onfleet/order-lookup", data);
}

export async function getBurqOrderList(data) {
  return callPostApiv2("orders/burq/order-lookup", data);
}

export async function getDesignerStatusList(data) {
  return callPostApiv2("orders/designer-status", data);
}

export async function getOccasions() {
  return callPostApiv2("occasions/list");
}

export async function checkGreetingsMessage(data) {
  return callPostApiv2("occasions/check-greetings-message", data);
}

export async function getAddressTypes() {
  return callPostApiv2("address-types/list");
}

export async function getDeliveryTypes(data) {
  return callPostApiv2("orders/delivery-type/list", data);
}

export async function getDeliveryTimes(data) {
  return callPostApiv2("delivery-matrix/list-by-date", data);
}

export async function getPaymentTypes() {
  return callGetApiv2("orders/payment-type/list");
}

export async function addStockOrder(data) {
  return callPostApiv2("orders/stock/create", data);
}

export async function assignDesignerOrder(data) {
  return callPostApiv2("orders/designer/assignorder", data);
}

export async function assignDesignerStockOrder(data) {
  return callPostApiv2("orders/designer/stock/assignorder", data);
}

export async function deleteOrder(data) {
  return callPostApiv2("orders/set-delete-order", data);
}

export async function setFraudOrder(data) {
  return callPostApiv2("orders/set-fraud-order", data);
}

export async function unsetFraudOrder(data) {
  return callPostApiv2("orders/unset-fraud-order", data);
}

export async function getOrderDetailsById(id) {
  return callGetApiv2("orders/orderdetail/" + id);
}

export async function updateOnfleetOrder(data) {
  return callPostApiv2("orders/update-onfleet-order", data);
}
export async function updateBurqOrder(data) {
  return callPostApiv2("orders/update-burq-order", data);
}
export async function createOrder(orderData) {
  return callPostApiv2(`orders/create`, orderData);
}
export async function addInternalNotes(data) {
  return callPostApiv2(`orders/add-internal-note`, data);
}
export async function getPetalrewardsConfig() {
  return callGetApiv2(`configuration/10`);
}
export async function getUpchargelist(notoken) {
  return callPostApiv2(`store-settings/upcharge-date/list`, {}, {}, notoken);
}
export async function getBlockoutDate(notoken) {
  return callPostApiv2(`blockout-dates/list`, {}, {}, notoken);
}
export async function editOrder(data) {
  return callPostApiv2(`orders/update`, data);
}
export async function udpateOrder(data) {
  return callPostApiv2(`orders/update`, data);
}
export async function cancelOrder(data) {
  return callPostApiv2(`orders/set-cancel-order`, data);
}
export async function getBlockOutDateForProduct(data) {
  return callPostApiv2(`blockout-dates/list`, data);
}
export async function getFrequnceyList() {
  return callGetApiv2(`orders/subscription/frequency/list`);
}
export async function fraudOrder(data) {
  return callPostApiv2(`orders/set-fraud-order`, data);
}
export async function holidaycutoffall(notoken) {
  let postreq = {
    sortBy: [],
    globalSearch: "",
    filters: [],
  };
  return callPostApiv2(`holiday-cutoff/all`, postreq, {}, notoken);
}

export async function getUpchargeListActive() {
  return callPostApiv2("store-settings/upcharge-date/listactive");
}
export async function getBlockOutListActive() {
  return callPostApiv2("blockout-dates/listactive");
}
export async function getHolidayListActive() {
  return callPostApiv2("holiday-cutoff/allactive");
}

export async function printGreetingMessage(data) {
  return callPostApiv2("orders/designer/print-message", data);
}

export async function orderMailSend(data) {
  return callPostApiv2("orders/order-mail/resend",data);
}

export async function getOrderListExport(data) {
  return callPostApiv2("orders/order-lookup/export", data);
}

export async function getSubscriptionOrder(data) {
  return callPostApiv2("orders/subscription-list", data);
}

export async function getOrderEditByDetail(data) {
  return callPostApiv2("orders/check-order/edited-by", data);
}
export async function updateOrderEditByDetail(data) {
  return callPutApiv2("orders/update-order/edited-by", data);
}
export async function removeUpdateOrderLastEditBy(data) {
  return callPutApiv2("orders/update-order/remove-last-editby", data);
}

export async function orderDesignerStatusMarkAsCompleted(data) {
  return callPostApiv2("orders/designer-status/mark-complete", data);
}

export async function getSubscriptionSubOrder(data) {
  return callPostApiv2("orders/subscription-suborder-list", data);
}

export async function orderDesignerStatusBulkMarkAsCompleted(data) {
  return callPostApiv2("orders/designer-status/mark-complete-bulk", data);
}

export async function orderDesignerBulkPrintCard(data) {
  return callPostApiv2("orders/mass-print-message", data);
}

export async function transferToDifferentStore(data){
  return callPostApiv2("orders/transfer_order",data)
}