import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
// Components
import Header from "./../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import { MODULES, STATUS_FILTER } from "../../../Helpers/Constants";
// Services
// import RecipeSettingsApi from "../../../Services/RecipeSettings/RecipeSettings";
// import UtilsApi from "../../../Services/Utils/Utils";
import { updateStatus } from "../../../api/utils";
import {
  unitList,
  typeList,
  getRecipeRawMaterial,
  deleteRawMaterial,
  updateRawMaterial,
  addRawMaterial,
  unitUpdate,
  addUnit,
  unitDeleteData,
  typeUpdate,
  addType,
  typeDeleteData,
} from "../../../api/recipeSetting";
// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
import AddIcon from "./../../../Assets/images/add.svg";
//From
import Text from "../../../Components/FormControl/Text/Text";
// import PetalRewordForm from "./PetalRewordForm";
//Model Component
import RowMaterial from "../../../Components/Modal/RecipeSetting/RecipeModel";
import Chip from "@material-ui/core/Chip";
//styles
import styles from "./index.module.scss";

const RecipeSetting = (props) => {
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  const [type, setType] = useState("");
  const [unit, setUnit] = useState("");
  const [unitData, setUnitData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [access, setAccess] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = React.useState({
    isDelete: false,
    id: null,
  });
  // const recipeSettingsService = new RecipeSettingsApi();
  //Modal
  //   const [showRowMaterial, setShowRowMaterial] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [isEdit, setEditStatus] = useState({ data: {}, editing: false });
  const [unitEdit, setEditUnit] = useState({});
  const [typeEdit, setEditType] = useState({});
  const [defaultFilter, setDefaultFilter] = useState({
    currentPage: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
  });
  const statusList = STATUS_FILTER;
  const [statusRawMaterialId, setStatusRawMaterialId] = useState(false);
  const [statusOfRawMaterial, setStatusOfRawMaterial] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  // const utilsService = new UtilsApi();

  const fetchUnitList = async () => {
    let postUnitData = {
      currentPage: 0,
      itemsPerPage:200
    };
    // const response = await recipeSettingsService.unitList(postUnitData);
    const response = await unitList(postUnitData);
    if (response && response.status === 200) {
      setUnitData(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const fetchTypeList = async () => {
    let postUnitData = {
      currentPage: 0,
      itemsPerPage:200
    };
    // const response = await recipeSettingsService.typeList(postUnitData);
    const response = await typeList(postUnitData);
    if (response && response.status === 200) {
      setTypeData(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  useEffect(() => {
    fetchUnitList();
    fetchTypeList();
  }, []);

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.RECIPE_SETTINGS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  // const handleRecipeSettingClick = async (event, id, status) => {
  //   event.preventDefault();
  // };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };
    //Api Call

    // const response = await recipeSettingsService.getRecipeRawMaterial(postData);
    const response = await getRecipeRawMaterial(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  const deleteRaw = async () => {
    // const response = await recipeSettingsService.deleteRawMaterial(
    const response = await deleteRawMaterial(showDeleteModal.id);
    if (response && response.status === 200) {
      setShowDeleteModal({ isDelete: false, id: null });
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      setShowDeleteModal({ isDelete: false, id: null });
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleStatusRawMaterialClick = async (event, id, status) => {
    event.preventDefault();

    setStatusRawMaterialId(id);
    setStatusOfRawMaterial(status);
    setShowStatusModal(true);
  };

  const handleRawMaterialStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfRawMaterial === 1 ? 0 : 1,
      id: statusRawMaterialId,
      table: "product_recipe_raw_materials",
      auditLogEntries:true
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusRawMaterialId(null);
      setStatusOfRawMaterial(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  //   const updateStatus = async (data) => {
  //     const postData = { ...data };
  //     postData.status = !postData.status;
  // 	let postData = {
  // 		status: statusOfHoliday === 1 ? 0 : 1,
  // 		id: statusHolidayId,
  // 		table: "holiday_cutoffs",
  // 	  };

  //     const response = await recipeSettingsService.updateRawMaterial(
  //       data.id, postData
  //     );
  //     if (response && response.status === 200) {
  // 		setStatusRawMaterialId(null);
  // 		setStatusOfRawMaterial(null);
  // 		setShowStatusModal(false);
  //       showToastMessage(
  //         undefined,
  //         response.data.message,
  //         "success",
  //         false
  //       );
  //       fetchData(defaultFilter)
  //     }
  //   }

  let recipeAccess = getAccessByModuleName(modules, MODULES.RECIPE_SETTINGS);
  const columns = React.useMemo(
    () => [
      {
        Header:"Image",
        accessor:"image",
        isVisible:true,
        disableFilters: true,
        disableSortBy:true,
        Cell:({value,row,column})=>{
          return (
            <div className={styles.ImageWapper}>
              <div>
                {value !== "" &&
                value !== null &&
                value !== "null" &&
                value !== undefined ? (
                  <img
                    src={`${value}?w=70&q=75&auto=compress,format`}
                    alt="recipe image"
                  />
                ) : (
                  <div className={styles.emptyImage}>
                    <span>No Image</span>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Raw Material Name",
        accessor: "title",
        isVisible: true,
        placeholder: "Search by Raw material name",
      },
      {
        Header: "Type",
        accessor: "product_recipe_type",
        isVisible: true,
        placeholder: "Search by Type",
        Cell: ({ value, row, column }) => {
          return <div>{value.title}</div>;
        },
      },
      {
        Header: "Unit",
        accessor: "product_recipe_unit",
        isVisible: true,
        placeholder: "Search by Unit",
        Cell: ({value,row,column}) => {
          return <div>{ value?.title ?? "-" }</div>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        // disableFilters: true,
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className="d-flex justify-content-start">
              {recipeAccess && recipeAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt="Active"
                  onClick={(e) =>
                    handleStatusRawMaterialClick(e, value, row.original.status)
                  }
                />
              )}

              {recipeAccess && recipeAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={() => {
                    setEditStatus({ editing: true, data: row.original });
                    setShowAddModal(true);
                  }}
                />
              )}

              {recipeAccess && recipeAccess.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={() =>
                    setShowDeleteModal({ isDelete: true, id: value })
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    [data]
  );

  const handleRowMaterial = async (values) => {
    const postData = {
      product_recipe_types_id: values.type,
      image:values.image,
      title: values.title,
      status: 0,
      product_recipe_units_id:values.unit
    };
    let response;
    if (isEdit.editing) {
      postData.product_recipe_types_id = values?.type?.id
        ? values.type.id
        : values.type;
      postData.product_recipe_units_id = values?.unit?.id
      ? values.unit.id
      : values.unit
      postData.status = values.status

      response = await updateRawMaterial(isEdit.data.id, postData);
    } else response = await addRawMaterial(postData);

    if (response && response.status === 200) {
      setShowAddModal(false);
      setEditStatus({ data: {}, editing: false });
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const addUnitData = async () => {
    if (!unit) return;
    //Api Call
    let response;
    const postData = {
      title: unit,
    };
    if (unitEdit.id) {
      // response = await recipeSettingsService.unitUpdate(unitEdit.id, postData);
      response = await unitUpdate(unitEdit.id, postData);
    } else {
      // response = await recipeSettingsService.addUnit(postData);
      response = await addUnit(postData);
    }
    if (response && response.status === 200) {
      setUnit("");
      showToastMessage(undefined, response.data.message, "success", false);
      fetchUnitList();
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const unitDelete = async (id) => {
    // const response = await recipeSettingsService.unitDelete(id);
    const response = await unitDeleteData(id);
    if (response && response.status === 200) {
      showToastMessage(undefined, response.data.message, "success", false);
      fetchUnitList();
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const addTypeData = async () => {
    if (!type) return;
    //Api Call
    let response;
    const postData = {
      title: type,
    };
    if (typeEdit.id) {
      // response = await recipeSettingsService.typeUpdate(typeEdit.id, postData);
      response = await typeUpdate(typeEdit.id, postData);
    } else {
      // response = await recipeSettingsService.addType(postData);
      response = await addType(postData);
    }
    if (response && response.status === 200) {
      setType("");
      showToastMessage(undefined, response.data.message, "success", false);
      fetchTypeList();
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const typeDelete = async (id) => {
    // const response = await recipeSettingsService.typeDelete(id);
    const response = await typeDeleteData(id);
    if (response && response.status === 200) {
      showToastMessage(undefined, response.data.message, "success", false);
      fetchTypeList();
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <React.Fragment>
      <Header title="Recipe Settings" />
      <div id="MainContent" className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="column col-md-6 col-xs-12">
              <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <Text
                    label="Type"
                    value={type}
                    type="text"
                    onChange={(data) => setType(data)}
                    placeholder="Add Type"
                  />
                </div>
                {recipeAccess && recipeAccess.permissions.write_access && (
                  <div
                    className={`link cursor-pointer ml-2 mt-4 ${styles.searchIconWrapper}`}
                    onClick={(e) => addTypeData()}
                  >
                    <img
                      className={styles.searchIcon}
                      src={AddIcon}
                      alt="Add"
                    />
                  </div>
                )}
              </div>
              <div className={styles.list}>
                {typeData.map((item) => (
                  <div className={styles.element}>
                    <Chip
                      label={item.title}
                      clickable
                      onClick={() => {
                        setType(item.title);
                        setEditType(item);
                      }}
                      onDelete={
                        recipeAccess &&
                        recipeAccess.permissions.delete_access &&
                        (() => typeDelete(item.id))
                      }
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="column col-md-6 col-xs-12">
              <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <Text
                    label="Unit"
                    value={unit}
                    type="text"
                    onChange={(data) => setUnit(data)}
                    placeholder="Add Unit"
                  />
                </div>
                {recipeAccess && recipeAccess.permissions.write_access && (
                  <div
                    className={`link cursor-pointer ml-2 mt-4 ${styles.searchIconWrapper}`}
                    onClick={(e) => addUnitData()}
                  >
                    <img
                      className={styles.searchIcon}
                      src={AddIcon}
                      alt="Add"
                    />
                  </div>
                )}
              </div>
              <div className={styles.list}>
                {unitData.map((item) => (
                  <div className={styles.element}>
                    <Chip
                      label={item.title}
                      clickable
                      onClick={() => {
                        setUnit(item.title);
                        setEditUnit(item);
                      }}
                      onDelete={
                        recipeAccess &&
                        recipeAccess.permissions.delete_access &&
                        (() => unitDelete(item.id))
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-6 col-xs-12">
            <div className={styles.title}>Raw Material</div>
            {recipeAccess && recipeAccess.permissions.write_access && (
              <div
                className={`link cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
                onClick={() => setShowAddModal(true)}
              >
                <img className={styles.searchIcon} src={AddIcon} alt="Add" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-5">
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>

      {showAddModal && (
        <RowMaterial
          showModal={showAddModal}
          pageTitle={isEdit.editing ? 
            "Update Raw Material": 
            "Add Raw Material"}
          submitStatus={submitStatus}
          isEdit={isEdit.editing}
          editData={isEdit.data}
          handleCancelClick={(e) => {
            setShowAddModal(false);
            setEditStatus({ data: {}, editing: false });
          }}
          handleSubmitClick={handleRowMaterial}
          onChange={() => {}}
          showStatus={isEdit.editing}
        />
      )}

      <DeleteConfirmation
        showModal={showDeleteModal.isDelete}
        title="Delete Recipe Setting"
        message="Are you sure want to delete this recipe raw material?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal({ isDelete: false, id: null });
          // setDeleteNotesId(null);
        }}
        handleSubmitClick={deleteRaw}
      />
      <StatusConfirmation
        showModal={showStatusModal}
        title={"Recipe Raw material Dates"}
        message={"recipe raw material?"}
        buttonLabel={statusOfRawMaterial ? "Inactivate" : "Activate"}
        statusFlag={statusOfRawMaterial}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusRawMaterialId(null);
          setStatusOfRawMaterial(null);
        }}
        handleSubmitClick={handleRawMaterialStatus}
      />
    </React.Fragment>
  );
};
export default RecipeSetting;
